import { useIsomorphicLayoutEffect } from 'ahooks';
import { useState } from 'react';

const WEBM_BLACKLIST_DEVICE = [
    'Android\\s[1-9]|10\\.', // anything below android 11
];

export const useIsWebmLegacyDevice = (userAgent?: string): boolean => {
    const [isBlackListed, setIsBlackListed] = useState(false);

    useIsomorphicLayoutEffect(() => {
        const agent = userAgent ?? navigator?.userAgent;
        const isDeviceBlacklisted = WEBM_BLACKLIST_DEVICE.some((deviceRegex) =>
            new RegExp(deviceRegex).test(agent)
        );
        setIsBlackListed(isDeviceBlacklisted);
    }, [userAgent]);
    return isBlackListed;
};
